
#vwListStory{
	background-color: black;
	position: fixed;
	top: 0;
}

#vwWrapStory{
	position: absolute;
	top: 0;
	left: 0;
}

#vwScrollStory{
	scroll-behavior: unset; 
	overscroll-behavior: none;
	width: 100vw;
	/* background: linear-gradient(blue,red,pink); */
	opacity: 0.97;
	overflow-y: scroll;
}

.rowStoryMenuHeader{
	display: 'flex', 
}

.rowStoryMenuIconChart{
	margin-left: 8px;
}

.rowStoryMenuNumChart{
	color: white;
	font-weight: 700;
}

.btnStoryExpand{
	margin-top: 0;
	margin-bottom: 0;
	margin-left: auto;
	margin-right: 8px;
	height: 30px;
}

.rowStoryMenuPlay{
	position: absolute;
	left: 0px;
	bottom: 0px;
}

.swiperStoryMenuRight{
	position: absolute;
	bottom: 0;
}

.btnStoryLikeIcon{
	
	color: white;
	margin: 0;
	height: 40px;
}

.btnStoryLikeNum{
	color: white;
	margin: 0;
}


.btnStoryMute{

}

.iconPopupLike {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(-12deg);
	opacity: 0; /* 最初は非表示にする */
	transition: opacity 0.3s ease-in-out; /* アニメーションを設定 */
}
  
.iconPopupLike.show {
	opacity: 1; /* アイコンを表示する場合のスタイル */
}

#ageVerif{
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	padding-top: 40vh;
	z-index: 3;
	
}
#ageVerif div{
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	-ms-filter: blur(40px);
	filter: blur(40px);
}

#ageVerif div div{
	width: 100vw;
	height: 100vh;
	color: black;
	background-color: black;
}

#ageVerif span{
	position: absolute;	
	left: 0;
	width: 100vw;
}

#ageVerif h1{
	margin-right: auto;
	font-size: 20px;
	color: white;
}

#ageVerif p{
	font-size: 12px;
	color: white;
}

#ageVerif button{
	margin-top: 10vh;
    font-size: 20px;
	font-weight: 900;
	background-color: white;
    color: white;
    background-color: transparent; 
    border: 2px solid white;
    border-radius: 10px; 
}